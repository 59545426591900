@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700;800;900&display=swap');

.Styled-item {
  margin-top: 10%;
  border-radius: 30px;
  border: 3px solid rgb(253,235,26);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 200px;
}

.Number {
  color: #fff;
  font-family: 'Barlow';
  font-weight: 600;
  font-size: 60px;              
  text-decoration: none;
  margin-top: 0;
  margin-bottom: -8%
  
}

.Number-info {
  color: #fff;
  font-family: 'Barlow';
  font-Size: 12px;
  text-decoration: none;
  font-weight: 600;
  margin: 0%
}

.Info-up {
  color: #fff;
  font-family: 'Barlow';
  font-size: 12px;
  text-decoration: none;
  margin-bottom: 0;
  font-weight: 600
}

.Info-bottom {
  color: #1F0A57;
  font-family: 'Barlow';
  font-size: 10px;
  margin-top: 0;
  font-weight: 600;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  margin-left: 2em;
  margin-right: 2em;
}

.Preço {
  color: #fff;
  font-family: 'Barlow';
  font-size: 30px;
  text-decoration: 'none';
  margin-top: 30;
  margin-bottom: 0;
  font-weight: 600
}

.Assinar {
  color: '#1F0A57';
  font-size: 12px;
  font-family: 'Barlow';
  font-weight: 700;
  border-radius: 20px;
  background: #FDEB1A;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  text-decoration: none;
}

/* hide disabled buttons */
.rec.rec-arrow {
  color: #FDEB1A;
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}

.rec.rec-arrow:active {
  color: #FDEB1A
}

.TextoSuperior {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  color: white;
}

