.App-logo {
  width: 10%;
}

.teste{
  font-family: 'Barlow';
  font-weight: 700;
}

.Button {
  font-family: 'Barlow';
  font-weight: 600;
}
/* Increase the specificity */
.Button:disabled {
  color: red;
}