.App {
  text-align: center;
}

h2 {
  color: #FFF;
  text-align: center;
}

h3 {
  color: #FFF;
  text-align: center;
}

p {
  color: #FFF;
}

li {
  color: #FFF;
}

.App-banner {
  width: 100%;
  padding-bottom: 0,
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.teste{
  font-family: 'Barlow';
  font-weight: 600;
}
